import Button from "../common/Button";
import {isBlockActive, toggleBlock} from "../utils/SlateUtilityFunctions";
import {Transforms} from "slate";
import Icon from "../common/Icon";
import "./Input.css";
import {RiCloseCircleFill} from 'react-icons/ri'
import React, {useContext, useState} from "react";
import {GlobalStateContext} from "../utils/globatState";
import defaultToolbarGroups from "../Toolbar/toolbarGroups";

const InputButton = ({ editor, format }) => {
    const globalState = useContext(GlobalStateContext)
    const [selected, setSelected] = useState(null);
    const [closed, setClosed] = useState(false);
    const optionClickHandler = (o, i) => {
        setSelected(i);
    }
    const insertText = () => {
        if(selected === null) return;
        const o = globalState.options[selected];
        if(editor.blurSelection) editor.selection = editor.blurSelection;
        editor.insertText('{'+o+'}', {select: "true"})
    }
    const insertField = () => {
        if(selected === null) return;
        const o = globalState.options[selected];
        if(editor.blurSelection) editor.selection = editor.blurSelection;
        const input = {type:'input', customField:o, children:  [{ text: '' }]}
        Transforms.insertNodes(editor, input, {select: "true"});
    }
    return (
        <div style={{display:"inline-block"}}>
            <Button
                active={!closed}
                format={format}
                onMouseDown={(e) => {
                    e.preventDefault();
                    setClosed(!closed)
                }}
            >
                <Icon icon={format} />
            </Button>
            <div className="inputList" style={{display: closed ? 'none' : 'block'}}>
                <div>Custom Fields:</div>
                <div>
                    {globalState && globalState.options && globalState.options.map((o, i) => {
                        return <div className={"inputOption " + ((selected === i) ? 'selected' : '')} onClick={(e) => optionClickHandler(o, i)}>{o}</div>
                    })}
                </div>
                <Button className="inputButton" onClick={(e) => insertField()}>Insert Field</Button>
                <Button className="inputButton" onClick={(e) => insertText()}>Insert Text</Button>
                <div className="icon" onClick={(e) => setClosed(true)}><RiCloseCircleFill size={20}/></div>
            </div>
        </div>
    );
};

export default InputButton;