import React from "react";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import { TableUtil } from "../../utils/table.js";
import {activeMark, addMarkData} from "../../utils/SlateUtilityFunctions";

const InHeader = ({ editor }) => {
  const table = new TableUtil(editor);
    const changeMarkData = (event, format) => {
        event.preventDefault();
        const value = event.target.value;
        addMarkData(editor, { format, value });
    };
  const handleButtonClick = (action) => {
    switch (action) {
      case "page":
          editor.insertText('{CurrrentPage}');
        break;
      case "total":
          editor.insertText('{TotalPages}');
        break;
      case "date":
          editor.insertText('{CurrentDate}');
        break;
      default:
        return;
    }
  };

  return (
    <>

      <Button format="currentpage" onClick={() => handleButtonClick("page")}>
        <Icon icon="pagenumber" />
      </Button>
      <Button
        format="totalpages"
        onClick={() => handleButtonClick("total")}
      >
        <Icon icon="totalpages" />
      </Button>
      <Button format="date" onClick={() => handleButtonClick("date")}>
        <Icon icon="date" />
      </Button>
    </>
  );
};

export default InHeader;
