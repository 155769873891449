import { Transforms, Editor, Range, Element } from "slate";

export class TableUtil {
  constructor(editor) {
    this.editor = editor;
  }

  insertTable = (rows, columns) => {

    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      mode: "highest"
    });
    const [hf] = Editor.nodes(this.editor, {
      match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "header" || n.type === "footer",
      mode: "highest"
    });

    if (tableNode) return;
    if (!rows || !columns) {
      return;
    }
    const cellText = Array.from({ length: rows }, () =>
      Array.from({ length: columns }, () => "")
    );
    const newTable = createTableNode(cellText);

    Transforms.insertNodes(this.editor, newTable, {
      mode: hf ? 'lowest' : "highest"
    });
    Transforms.insertNodes(
      this.editor,
      { type: "paragraph", children: [{ text: "" }] },
      { mode: "highest" }
    );
  };

  changeClass = (newclass) => {
    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      mode: "highest"
    });
    if(!tableNode) return;
    const [oldTable, path] = tableNode;
    if(!tableNode) return;
    Transforms.setNodes(
        this.editor,
        {
          class: newclass,
        },
        { at: path },
    )
  };

  getClass = () => {
    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      mode: "highest"
    });
    //console.log(tableNode);
    if(!tableNode) return;
    const [oldTable, path] = tableNode;

    return oldTable.class || 'border';
  };

  setWidthValue = (data) => {
    this.editor.selection = this.editor.blurSelection;
    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      mode: "highest"
    });
    if(!tableNode) return;
    const [oldTable, path] = tableNode;
    Transforms.setNodes(
        this.editor,
        {
          widthData: data,
        },
        { at: path },
    );
    Transforms.setNodes(
        this.editor,
        {
          widthData: data,
        },
        {at: [path], match: (node, path) => (path.length === 3 || path.length === 4) && node.type === "table-cell"},
    )
  };

  getWidthValue = () => {

    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) => {
          //console.log(n);
          return !Editor.isEditor(n) && Element.isElement(n) && n.type === "table"},
      mode: "highest"
    });
    if(!tableNode) return;
    const [oldTable, path] = tableNode;
    let count = oldTable.children[0].children.length;
    let width = Math.floor(100/count);
    let data = [];
    for(let i = 0; i < count; i++)
      data.push(width);
    if(!oldTable.widthData) {

    }
    return oldTable.widthData || data.join(',');
  };

  insertCells = (tableNode, path, action) => {
    let existingText = Array.from(tableNode.children, (rows) =>
      Array.from(rows.children, (arr) => arr.children)
    );
    const columns = existingText[0].length;
    if (action === "row") {
      existingText.push(Array(columns).fill(""));
    } else {
      existingText = Array.from(existingText, (item) => {
        item.push("");
        return item;
      });
    }
    const newTable = createTableNode(existingText);

    Transforms.insertNodes(this.editor, newTable, {
      at: path
    });
  };

  removeTable = () => {
    Transforms.removeNodes(this.editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      mode: "highest"
    });
  };

  insertRow = () => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table"
      });
      if (tableNode) {
        const [oldTable, path] = tableNode;
        this.removeTable();
        this.insertCells(oldTable, path, "row");
      }
    }
  };

  insertColumn = () => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table"
      });
      if (tableNode) {
        const [oldTable, path] = tableNode;
        this.removeTable();
        this.insertCells(oldTable, path, "columns");
      }
    }
  };
}

const createRow = (cellText) => {
  const newRow = Array.from(cellText, (value, i) => createTableCell(value, i));
  return {
    type: "table-row",
    children: newRow
  };
};

const createTableCell = (text, i = null) => {
  let cell = {
    type: "table-cell",
    children: text ? text : [{ type:"paragraph", children:[{ type:"paragraph", children:[{text: ''}]}]}]
  };
  if(i !== null) cell.ind = i;
  console.log(cell);
  return cell;
};

const createTableNode = (cellText) => {
  const tableChildren = Array.from(cellText, (value) => createRow(value));
  let tableNode = { type: "table", children: tableChildren };
  return tableNode;
};
