import React, {useCallback, useEffect, useMemo, useRef, useState, useReducer, useContext} from 'react';
import {createEditor, Editor, Transforms} from 'slate';
import { withHistory } from "slate-history";
import {Slate, Editable, withReact } from 'slate-react';
import Toolbar from './Toolbar/Toolbar'
import { sizeMap, fontFamilyMap } from './utils/SlateUtilityFunctions.js'
import withLinks from './plugins/withLinks.js'
import withTables from './plugins/withTable.js'
import withEmbeds from './plugins/withEmbeds.js'
import './Editor.css'
import Link from'./Elements/Link/Link'
import Image from './Elements/Image/Image'
import Video from './Elements/Video/Video'
import GlobalState, {GlobalStateContext, reducer} from "./utils/globatState";
import escapeHtml from 'escape-html'
import withInputChecks from "./plugins/withInputChecks";

const initialState = {
    showHeader: false,
    showFooter: false.valueOf()
};

const Element = (props) =>{
    const globalState = useContext(GlobalStateContext)
    const {attributes, children, element} = props;
    switch(element.type){
        case 'headingOne':
            return <h1 {...attributes}>{children}</h1>
        case 'headingTwo':
            return <h2 {...attributes}>{children}</h2>
        case 'headingThree':
            return <h3 {...attributes}>{children}</h3>
        case 'blockquote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'alignLeft':
            return <div style={{textAlign:'left',listStylePosition:'inside'}} {...attributes}>{children}</div>
        case 'alignCenter':
            return <div style={{textAlign:'center',listStylePosition:'inside'}} {...attributes}>{children}</div>
        case 'alignRight':
            return <div style={{textAlign:'right',listStylePosition:'inside'}} {...attributes}>{children}</div>
        case 'list-item':
            return  <li {...attributes}>{children}</li>
        case 'orderedList':
            return <ol type='1' {...attributes}>{children}</ol>
        case 'unorderedList':
            return <ul {...attributes}>{children}</ul>
        case 'link':
            return <Link {...props}/>
        case 'repeat':
            return <div style={{padding: '5px', border: '1px dashed #a8a8a8', position: 'relative', marginTop: '5px', marginBottom: '5px'}} {...attributes}><div style={{position:'absolute', fontSize: '10px', color: 'grey', top:'-7px', background: 'white', left: '45%', pointerEvents: 'none'}}>Repeat Block</div>{children}</div>
        case 'pagebreak':
            return <div  style={{padding: '0px', border: '1px dashed #a8a8a8', position: 'relative', marginTop: '5px', marginBottom: '5px'}} {...attributes}><div style={{position:'absolute', fontSize: '10px', color: 'grey', top:'-7px', background: 'white', left: '45%', pointerEvents: 'none'}}>Page Break</div>{children}</div>
        case 'header':
            return <div  style={{display: globalState.showHeader ? 'block' : 'none', padding: '3px', border: '1px dashed #a8a8a8', position: 'relative', marginTop: '5px', marginBottom: '10px'}} {...attributes}><div style={{position:'absolute', fontSize: '10px', color: 'grey', top:'-7px', background: 'white', left: '45%', pointerEvents: 'none'}}>Page Header</div>{children}</div>
        case 'footer':
            return <div  style={{display: globalState.showFooter ? 'block' : 'none', padding: '3px', border: '1px dashed #a8a8a8', position: 'relative', marginTop: '5px', marginBottom: '10px'}} {...attributes}><div style={{position:'absolute', fontSize: '10px', color: 'grey', top:'-7px', background: 'white', left: '45%', pointerEvents: 'none'}}>Page Footer</div>{children}</div>
        case 'input':
            return <span><input type="text" value={escapeHtml(element.customField)}/>{children}</span>
        case 'checkbox':
            return <span><input type="checkbox" value={escapeHtml(element.checked)}/>{children}</span>
        case 'table':
            return <table style={{border: element.class == "no-border" ? "dotted 1px #a7a7a7" : "1px solid black"}}>
                <tbody {...attributes}>{children}</tbody>
            </table>
        case 'table-row':
            return <tr {...attributes}>{children}</tr>
        case 'table-cell':
            let width = element.widthData ? element.widthData.split(',')[element.ind] : null;
            return <td {...attributes} style={width ? {width:width+"%"} : {}}>{children}</td>
        case 'image':
            return <Image {...props}/>
        case 'video':
            return <Video {...props}/>
        default :
            return <p {...attributes}>{children}</p>
    }
}
const Leaf = ({ attributes, children, leaf }) => {
    
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }
  
    if (leaf.code) {
      children = <code>{children}</code>
    }
  
    if (leaf.italic) {
      children = <em>{children}</em>
    }
    if(leaf.strikethrough){
        children = <span style={{textDecoration:'line-through'}}>{children}</span>
    }
    if (leaf.underline) {
      children = <u>{children}</u>
    }
    if(leaf.superscript){
        children = <sup>{children}</sup>
    }
    if(leaf.subscript){
        children = <sub>{children}</sub>
    }
    if(leaf.color){
        children = <span style={{color:leaf.color}}>{children}</span>
    }
    if(leaf.bgColor){
        children = <span style={{backgroundColor:leaf.bgColor}}>{children}</span>
    }
    if(leaf.fontSize){
        const size = leaf.fontSize
        children = <span style={{fontSize:size+'px'}}>{children}</span>
    }
    if(leaf.fontFamily){
        const family = fontFamilyMap[leaf.fontFamily]
        children = <span style={{fontFamily:family}}>{children}</span>
    }
    return <span {...attributes}>{children}</span>
}
const SlateEditor = ()=>{
    const [state, dispatch] = useReducer(reducer, initialState);
    const editorRef = useRef()
    if (!editorRef.current) editorRef.current = withHistory(withEmbeds(withInputChecks(withTables((withLinks(withReact(createEditor())))))));
    const editor = editorRef.current
    const [token, setToken] = useState(null)
    const [error, setError] = useState(null)
    const [value,setValue] = useState([
        {
            type:'paragaph',
            children:[{text:'Loading...'}],
        },
    ]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        async function fetchData(data) {
            const fetchUrl = 'https://'+urlParams.get('url')+'/formbuilderapi/fetchdata/'+urlParams.get('id')+(urlParams.get('temp') ? '/temp' : '');
            const response = await fetch(fetchUrl, data);
            setError('Document loading...');
            const json = await response.json();
            if(json.data) setValue(JSON.parse(json.data));
            setError(json.error);
            window.fbSaveUrl = json.saveUrl;
            window.recordset = json.recordset;
            window.fbGetImgUrl = json.getImgUrl;
            window.fbSaveImgUrl = json.saveImgUrl;
            dispatch({type: "OPTIONS", payload: json.options})

        }
        if(token) {
            setToken(token);
            localStorage.setItem("token", token);
        } else {
            setToken(localStorage.getItem("token"));
            const token = localStorage.getItem("token");
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': token ? `Token ${token}` : '',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            };
            if(urlParams.get('url') && urlParams.get('id'))
                fetchData(requestOptions);
            else
                setError('Url is missing...');
        }


    }, []);


    const renderElement = useCallback(props => <Element {...props}/>,[])

    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />
    }, [])


    if(error) return (
        <div style={{color: "red", background:"#ffd0d0", padding:"5px"}}>{error}</div>); else return (
        <GlobalState initialState={state} dispatch={dispatch}>
            <Slate editor = {editor} value = {value} onChange = {newValue => setValue(newValue)} >
                <Toolbar />
                <div className="editor-wrapper" style={{border:'1px solid #f3f3f3',padding:'0 10px'}}>
                    <Editable
                        placeholder='Write something'
                        renderElement={renderElement} 
                        renderLeaf={renderLeaf}
                        onBlur={(e) => {console.log('blur');editor.blurSelection = editor.selection;}}
                        onFocus={(e) => {console.log('focus');editor.blurSelection = null;}}
                        onKeyDown={event => {
                            if(event.key == 'Enter') {
                                const [tableNode] = Editor.nodes(editor, {
                                    match: (n) =>
                                        !Editor.isEditor(n) && n.type === "table-cell",
                                    mode: "highest"
                                });
                                if(!tableNode) return;
                                Transforms.splitNodes(editor, {
                                    always: true
                                });
                            }
                        }}
                    />
                </div>
            </Slate>
        </GlobalState>
    )
}

export default SlateEditor