import React from "react";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import { TableUtil } from "../../utils/table.js";
import {activeMark, addMarkData} from "../../utils/SlateUtilityFunctions";

const InTable = ({ editor }) => {
  const table = new TableUtil(editor);
    const changeMarkData = (event, format) => {
        event.preventDefault();
        const value = event.target.value;
        addMarkData(editor, { format, value });
    };
  const handleButtonClick = (action) => {
    switch (action) {
      case "row":
        table.insertRow();
        break;
      case "column":
        table.insertColumn();
        break;
      case "remove":
        table.removeTable();
        break;
      default:
        return;
    }
  };

  return (
    <>
        <select
            value={table.getClass()}
            onChange={(e) => table.changeClass(e.target.value)}
        >
            <option key={1} value="border">
                Default
            </option>
            <option key={2} value="no-border">
                No Border
            </option>
            <option key={3} value="header">
                With Header
            </option>

        </select>
      <Button format="insert row" onClick={() => handleButtonClick("row")}>
        <Icon icon="row" />
      </Button>
      <Button
        format="insert column"
        onClick={() => handleButtonClick("column")}
      >
        <Icon icon="column" />
      </Button>
      <Button format="remove table" onClick={() => handleButtonClick("remove")}>
        <Icon icon="removeTable" />
      </Button>
        <input value={table.getWidthValue()} onChange={(e) => table.setWidthValue(e.target.value)}/>
    </>
  );
};

export default InTable;
