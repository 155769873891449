// pwa/app/components/global-state/index.js

import React from "react";

// Set up global contexts
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

// Actions
export const SET_CART_ITEMS = "SET_CART_ITEMS";

// Reducer
export const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case "HEADER": {
            return {
                ...state,
                showHeader: payload
            };
        }
        case "FOOTER": {
            return {
                ...state,
                showFooter: payload
            };
        }
        case "OPTIONS": {
            return {
                ...state,
                options: payload
            };
        }
        default:
            return state;
    }
};

function GlobalState(props) {
    const { initialState, dispatch } = props;
    return (
        <GlobalStateContext.Provider value={initialState}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {props.children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    );
}

export default GlobalState;