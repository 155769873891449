import '../stylesheets/App.css';
import Something from './SlateEditor/Editor'
function App() {
    const urlParams = new URLSearchParams(window.location.search);
    const modal = urlParams.get('modal');
  return (
    <div className={"App " + (modal ? "modal" : "")}>
      <Something/>
    </div>
  );
}

export default App;

