import React from 'react';
import { MdFormatBold, MdFormatItalic, MdStrikethroughS, MdFormatUnderlined, MdFormatQuote, MdFormatAlignLeft, MdFormatAlignCenter, MdFormatAlignRight, MdFormatListNumbered, MdFormatListBulleted,MdInsertLink,MdVideoLibrary,MdImage,MdAdd,MdTextFields,MdCheckBox} from 'react-icons/md'
import { BsTypeH1, BsTypeH2, BsTypeH3} from 'react-icons/bs'
import { ImPageBreak } from 'react-icons/im'
import { FaSave,FaSuperscript,FaSubscript,FaCalendarAlt } from 'react-icons/fa'
import { FiRepeat, FiBookOpen } from 'react-icons/fi'
import { RiLayoutBottom2Line, RiLayoutTop2Line, RiFile3Line} from 'react-icons/ri'
import { AiOutlineTable, AiOutlineInsertRowBelow, AiOutlineInsertRowRight,AiOutlineDelete } from 'react-icons/ai'
const iconList={
    pagenumber:<RiFile3Line size={20}/>,
    input:<MdTextFields size={20}/>,
    checkbox:<MdCheckBox size={20}/>,
    totalpages:<FiBookOpen size={20}/>,
    date:<FaCalendarAlt size={20}/>,
    save:<FaSave size={20}/>,
    footer:<RiLayoutBottom2Line size={20}/>,
    header:<RiLayoutTop2Line size={20}/>,
    pagebreak:<ImPageBreak  size={20}/>,
    repeat:<FiRepeat  size={20}/>,
    bold:<MdFormatBold size={20}/>,
    italic:<MdFormatItalic size={20}/>,
    strikethrough:<MdStrikethroughS size={20}/>,
    underline:<MdFormatUnderlined size={20}/>,
    headingOne:<BsTypeH1 size={20}/>,
    headingTwo:<BsTypeH2 size={20}/>,
    headingThree:<BsTypeH3 size={20}/>,

    blockquote:<MdFormatQuote size={20}/>,
    superscript:<FaSuperscript size={15}/>,
    subscript:<FaSubscript size={15}/>,
    alignLeft:<MdFormatAlignLeft size={20}/>,
    alignCenter:<MdFormatAlignCenter size={20}/>,
    alignRight:<MdFormatAlignRight size={20}/>,
    orderedList:<MdFormatListNumbered size={20}/>,
    unorderedList:<MdFormatListBulleted size={20}/>,
    link:<MdInsertLink size={20}/>,
    image:<MdImage size={20}/>,
    video:<MdVideoLibrary size={20}/>,
    add:<MdAdd size={20}/>,
    table:<AiOutlineTable size={20}/>,
    row:<AiOutlineInsertRowBelow size={20}/>,
    column:<AiOutlineInsertRowRight size={20}/>,
    removeTable:<AiOutlineDelete size={20}/>
}




const Icon = (props)=>{
    const {icon} = props
    return(
        iconList[icon]
    )
}

export default Icon;