import React, {useContext, useEffect, useState} from "react";
import {ReactEditor, useSlate} from "slate-react";
import Button from "../common/Button";
import Icon from "../common/Icon";
import {
  toggleBlock,
  toggleMark,
  isMarkActive,
  addMarkData,
  isBlockActive,
  activeMark
} from "../utils/SlateUtilityFunctions.js";
import useTable from "../utils/useTable.js";
import useHeader from "../utils/useHeader.js";
import defaultToolbarGroups from "./toolbarGroups.js";
import "./styles.css";
import ColorPicker from "../Elements/Color Picker/ColorPicker";
import LinkButton from "../Elements/Link/LinkButton";
import Embed from "../Elements/Embed/Embed";
import Table from "../Elements/Table/Table";
import InTable from "../Elements/Table/InTable";
import {Editor, Element, Range, Range as LIST_TYPES, Transforms} from "slate";
import {GlobalDispatchContext, GlobalStateContext} from "../utils/globatState";
import InHeader from "../Elements/Table/InHeader";
import InputButton from "../Elements/InputButton";

const Toolbar = () => {
  const editor = useSlate();
  const isTable = useTable(editor);
  const [toolbarGroups, setToolbarGroups] = useState(defaultToolbarGroups);
    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    let filteredGroups = [...defaultToolbarGroups];
    if (isTable && false) {
      filteredGroups = toolbarGroups.map((grp) =>
        grp.filter((element) => element.type !== "block")
      );
      filteredGroups = filteredGroups.filter((elem) => elem.length);
    }
    setToolbarGroups(filteredGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTable]);

  const SaveButton = ({ format }) => {
    const [message, setMessage] = useState(null)
    return (
        <div>
          <Button
              active={isBlockActive(editor, format)}
              format={format}
              onMouseDown={(e) => {
                e.preventDefault();
                const token = localStorage.getItem("token");
                fetch(window.fbSaveUrl, {
                  method: "post",
                  headers: {
                    'Authorization': token ? `Token ${token}` : '',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    bfdata: editor.children,
                    html: null,
                    recordset: window.recordset,

                  })
                })
                    .then(response => response.json())
                    .then(data => {
                      setMessage(data.message);
                        window.parent.postMessage('saved', "*");
                      setTimeout( function() { setMessage(null) }, 2000);
                    });
              }}
          >
            <Icon icon={format} />
          </Button>
          <div style={{margin:'auto', 'position': 'absolute', top:'5px', color: "#5f6368", fontSize: "15px"}}>{message}</div>
        </div>
    );
  };

    const RepeatButton = ({ format }) => {
        return (
            <div style={{display:"inline-block"}}>
                <Button
                    active={isBlockActive(editor, format)}
                    format={format}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        const isActive = isBlockActive(editor, 'repeat')

                        const block = { type: 'repeat', children: [] }
                        if(isActive) {
                            console.log(editor.selection.anchor.path[0]);
                            Transforms.unwrapNodes(editor, { match: n => n.type == 'repeat' })
                        } else {
                            Transforms.wrapNodes(editor, block)
                        }
                    }}
                >
                    <Icon icon={format} />
                </Button>
            </div>
        );
    };

    const FontSizeInput = ({ format }) => {
        return (
            <span style={{lineHeight: '26px', marginLeft: '5px'}}>
                <input type="number" value={activeMark(editor, format) || 16}
                       onChange={(e) => {
                           ReactEditor.focus(editor);
                           changeMarkData(e, format);
                           editor.blurSelection && Transforms.select(editor, editor.blurSelection);
                       }} min="1" max="999" style={{width:"50px"}}/> px
            </span>
        );
    }

    const HeaderButton = ({ format }) => {
        return (
            <div style={{display:"inline-block"}}>
                <Button
                    active={globalState.showHeader}
                    format={format}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        let isActive = false;
                        const header = editor.children ? editor.children.filter(n => {
                            return n.type == 'header';
                        }) : [];

                        if(!header.length)
                        {
                            const block = { type: 'header', children: [{ type: 'paragraph', children: [{text:'' }]}]}
                            Transforms.insertNodes(editor, block, {
                                at: [0]
                            });
                        }
                        dispatch({type: "HEADER", payload: !globalState.showHeader})

                    }}
                >
                    <Icon icon={format} />
                </Button>
            </div>
        );
    };

    const FooterButton = ({ format }) => {
        return (
            <div style={{display:"inline-block"}}>
                <Button
                    active={globalState.showFooter}
                    format={format}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        let isActive = false;
                        const header = editor.children ? editor.children.filter(n => {
                            return n.type == 'footer';
                        }) : [];

                        if(!header.length)
                        {
                            const block = { type: 'footer', children: [{ type: 'paragraph', children: [{text:'' }]}]}
                            Transforms.insertNodes(editor, block, {
                                at: [0]
                            });
                        }
                        dispatch({type: "FOOTER", payload: !globalState.showFooter})

                    }}
                >
                    <Icon icon={format} />
                </Button>
            </div>
        );
    };

    const BreakButton = ({ format }) => {
        return (
            <div style={{display:"inline-block"}}>
                <Button
                    active={isBlockActive(editor, format)}
                    format={format}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        if(!isBlockActive(editor, format)) {
                            Transforms.insertNodes(editor, {type:'pbreak', children: [{ text: '' }]},{select:"true"});
                            toggleBlock(editor, format);
                        }else {
                            Transforms.removeNodes(editor, {mode:"highest"});
                        }
                    }}
                >
                    <Icon icon={format} />
                </Button>
            </div>
        );
    };

    const CheckboxButton = ({ format }) => {
        return (
            <div style={{display:"inline-block"}}>
                <Button
                    active={isBlockActive(editor, format)}
                    format={format}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        const { selection } = editor;
                        const checkbox = {
                            type: 'checkbox',
                            checked: true,
                            children:  [{ text: '' }],
                        }
                        if(Range.isCollapsed(selection)){
                            Transforms.insertNodes(editor, checkbox, {select:true});
                        }
                        else{
                            Transforms.wrapNodes(editor, checkbox,{split:true})
                        }

                    }}
                >
                    <Icon icon={format} />
                </Button>
            </div>
        );
    };

  const BlockButton = ({ format }) => {
    return (
      <Button
        active={isBlockActive(editor, format)}
        format={format}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <Icon icon={format} />
      </Button>
    );
  };

  const MarkButton = ({ format }) => {
    return (
      <Button
        active={isMarkActive(editor, format)}
        format={format}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <Icon icon={format} />
      </Button>
    );
  };

  const Dropdown = ({ format, options }) => {
    return (
      <select
        value={activeMark(editor, format)}
        onChange={(e) => changeMarkData(e, format)}
      >
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    );
  };
  const changeMarkData = (event, format) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };

  return (
      <div>
        <div class="whiteback"></div>
        <div className="toolbar">
          {toolbarGroups.map((group, index) => (
            <span key={index} className={"toolbar-grp " + (group[0].type == 'inTable' ? (isTable ? 'temp' : 'no-border') : ((group[0].type == 'inHeader' ? (globalState.showFooter || globalState.showHeader ? 'temp' : 'no-border') : '')))}>
              {group.map((element) => {
                switch (element.type) {
                    case "save":
                        return <SaveButton key={element.id} {...element} />;
                    case "repeat":
                        return <RepeatButton format={element.format} key={element.id} {...element} />;
                    case "header":
                        return <HeaderButton format={element.format} key={element.id} {...element} />;
                    case "footer":
                        return <FooterButton format={element.format} key={element.id} {...element} />;
                    case "pagebreak":
                        return <BreakButton key={element.id} {...element} />;
                  case "block":
                    return <BlockButton key={element.id} {...element} />;
                  case "mark":
                    return <MarkButton key={element.id} {...element} />;
                  case "dropdown":
                    return <Dropdown key={element.id} {...element} />;
                  case "link":
                    return (
                      <LinkButton
                        key={element.id}
                        active={isBlockActive(editor, "link")}
                        editor={editor}
                      />
                    );
                  case "embed":
                    return (
                      <Embed
                        key={element.id}
                        format={element.format}
                        editor={editor}
                      />
                    );
                  case "color-picker":
                    return (
                      <ColorPicker
                        key={element.id}
                        activeMark={activeMark}
                        format={element.format}
                        editor={editor}
                      />
                    );
                    case "table":
                        return <Table key={element.id} editor={editor} />;
                    case "fontSize":
                        return <FontSizeInput format={element.format}/>;
                    case "input":
                        return <InputButton format={element.format} key={element.id} editor={editor} />;
                    case "checkbox":
                        return <CheckboxButton format={element.format} key={element.id} editor={editor} />;
                  case "inTable":
                    return isTable ? (
                      <InTable key={element.id} editor={editor} />
                    ) : null;
                    case "inHeader":
                        return globalState.showFooter || globalState.showHeader ? (
                            <InHeader key={element.id} editor={editor} />
                        ) : null;
                  default:
                    return <button>Invalid Button</button>;
                }
              })}
            </span>
          ))}
        </div>
      </div>
  );
};

export default Toolbar;
