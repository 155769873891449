import React, {useEffect, useRef, useState} from 'react';
import Button from '../../common/Button'
import Icon from '../../common/Icon'
import {isBlockActive} from '../../utils/SlateUtilityFunctions'
import usePopup from '../../utils/usePopup'
import {insertEmbed } from '../../utils/embed.js'
const Embed = ({editor,format}) =>{
    const urlInputRef = useRef();
    const [showInput,setShowInput] = usePopup(urlInputRef);
    const [error, setError] = useState(null)
    const [images, setImages] = useState([]);
    const [imageInd, setImageInd] = useState(null);
    const [formData,setFormData] = useState({
        url:'',
        width:100,
        height:100
    })
    const handleButtonClick = (e)=>{
        e.preventDefault();
        setShowInput(prev =>!prev);
    }

    useEffect(() => {
        loadImages();
    }, []);
    useEffect(() => {
        console.log(images);
    }, [images]);

    const loadImages = async () => {
        const fetchUrl = window.fbGetImgUrl;
        const response = await fetch(fetchUrl);
        setError('Images loading...');
        let json = await response.json();
        json = JSON.parse(json);
        if(json.images) setImages(json.images);
        setError(null);
    }

    const handleFormSubmit = (e)=>{
        e.preventDefault();
        insertEmbed(editor,{...formData},format);
        setShowInput(false);
        setFormData({
            url:'',
            width:'100',
            height:'100'
        })
    }

    const selectImage = (e, i)=>{
        e.stopPropagation();
        var element = document.getElementById("im"+i);
        var input = document.getElementById("imageurl");
        if(!showInput || !element) return;

        if(imageInd == i) {
            setImageInd(null);
            element.classList.remove("selected");
            setFormData(prev =>({...prev,url:''}))
        } else {
            var element1 = document.getElementById("im"+imageInd);
            if(element1) element1.classList.remove("selected");
            setImageInd(i);
            element.classList.add("selected");
            setFormData(prev =>({...prev,url:images[i]}))
        }

    }

    const handleSetImage = async (event) => {
        const {files} = event.target;
        let data = new FormData()
        let i = 0;
        for (const file of files) {
            data.append('images['+(i++)+']',file)
        }
        setError('Images uploading...');
        const response = await fetch(window.fbSaveImgUrl, {
            method: 'POST',
            body: data
        });
        loadImages();

    };

    const clickkFile = (e) => {
        e.stopPropagation();
        if(showInput && document.getElementById("imageinput")) document.getElementById("imageinput").click()
    }

    return (
        <div ref={urlInputRef} className='popup-wrapper'>
            <Button active={isBlockActive(editor,format)} style={{border: showInput?'1px solid lightgray':'',borderBottom: 'none'}}  format={format} onClick={handleButtonClick}>
                <Icon icon={format}/>
            </Button>
            {
                showInput&&
                <div  className='ipopup'>
                    {error && <div style={{color: '#8b8b8b'}}>{error}</div>}
                    <div className='popup-gallery'>
                        {
                            images && images.map((val, i) => {
                                return (
                                    <div id={"im"+i} className={"popup-image"} onClick={(e) => selectImage(e, i)}>
                                        <img src={val}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Button class="popup-button upload" onClick={(e) => clickkFile(e)}>Upload Images</Button>
                    <input type="file" multiple accept=".jpg, .png, .gif, .jpeg" id="imageinput" name="image" placeholder='Image' onChange={e => handleSetImage(e)}/>
                    <form onSubmit={handleFormSubmit}>
                        <input type="hidden" placeholder='Enter url' id="imageurl" value={formData.url} onChange={e=>setFormData(prev =>({...prev,url:e.target.value}))}/>
                        <input className="popupinput" type="text" placeholder='Enter width of frame' value={formData.width} onChange={e=>setFormData(prev =>({...prev,width:e.target.value}))} />
                        <input className="popupinput" type="text" placeholder='Enter height of frame' value={formData.height} onChange={e=>setFormData(prev =>({...prev,height:e.target.value}))} />

                        <Button type='submit' className="popupsavebutton">Insert Image</Button>
                    </form>
                </div>
            }
        </div>
    )
}

export default Embed;