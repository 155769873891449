import {Transforms} from "slate";
import {Editor, Range} from 'slate'

const withInputChecks = (editor)=>{
    const { isInline, deleteBackward } = editor

    editor.isInline = (element) => {
        return element.type === 'checkbox' || element.type === 'input' ? true : isInline(element)
    }

    editor.deleteBackward = (unit) => {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection)) {
            const [checkbox] = Editor.nodes(editor, {
                match: n => n.type === 'checkbox' || n.type === 'input',
            })

            if (checkbox) {
                Transforms.removeNodes(editor, { at: checkbox[1] })
                return
            }
        }

        deleteBackward(unit)
    }
    return editor;
};

export default withInputChecks;